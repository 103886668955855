import { SketchOutlined } from '@ant-design/icons';
import { Button, Card, Col, Empty, message, Modal, Row, Skeleton, Table, Tooltip } from 'antd';
import { useEffect, useState } from 'react';

import {
  addCommSep,
  CompanyRole,
  useConnection,
  useUserContext,
} from '@xeptagon_npm/kiuda-carbon-library';
import * as Icon from 'react-bootstrap-icons';
import { InfoCircle } from 'react-bootstrap-icons';
import { useLocation } from 'react-router-dom';
import { CompanyCreditBalance } from '../../../Definitions/InterfacesAndType/company.creditBalance.columns.enum';
import { ProgramExchangeCredit } from '../../../Definitions/InterfacesAndType/company.exchangeCredit.enum';
import './companyCreditView.scss';
import { CreditTransferForm } from './creditTransferForm';

export const CompanyCreditView = (props: any) => {
  const { creditDetails, t } = props;

  const [creditTableData, setCreditTableData] = useState<any[]>([]);
  const [organizationDetails, setOrganizationDetails] = useState<any>();
  const [isShowCreditTransferModel, setIsShowCreditTransferModel] = useState(false);
  const { get } = useConnection();
  const state = useLocation();
  const { userInfoState } = useUserContext();
  const [isLoading, setIsLoading] = useState(true);
  const [messageApi, contextHolder] = message.useMessage();
  const [pageSize, setPageSize] = useState(5);

  const getOrganizationData = async () => {
    const response = await get(`national/organisation/profile?id=${state.state.record.companyId}`);
    if (response && response.data) {
      setOrganizationDetails(response.data);
    } else {
    }
    setIsLoading(false);
  };
  //   data &&
  //   ministrySectoralScope.includes(data.sectoralScope) &&
  //   userInfoState?.userRole !== Role.ViewOnly;

  useEffect(() => {
    const tableRows: ProgramExchangeCredit[] = [];
    for (const key in organizationDetails?.creditDistributionMap) {
      const currentProgramme = organizationDetails.creditDistributionMap[key];
      const singleProgramme: ProgramExchangeCredit = {
        programmeId: currentProgramme.programmeId,
        programmeName: currentProgramme.programmeName,
        sector: currentProgramme.sectorScope,
        quantity: currentProgramme.quantity,
        isProgrammeOwner: currentProgramme.isProgrammeOwner,
      };
      tableRows.push(singleProgramme);
    }
    setCreditTableData(tableRows);
  }, [organizationDetails]);

  useEffect(() => {
    getOrganizationData();
  }, []);

  const columns = [
    {
      title: t('companyDetails:programmeId'),
      dataIndex: CompanyCreditBalance.programmeId,
      key: CompanyCreditBalance.programmeId,
      width: '150px',
      align: 'left' as const,
      sorter: (a: any, b: any) => Number(a.programmeId) - Number(b.programmeId),
    },
    {
      title: t('companyDetails:programmeName'),
      dataIndex: CompanyCreditBalance.programmeName,
      key: CompanyCreditBalance.programmeName,
      sorter: false,
      align: 'left' as const,
    },
    {
      title: t('companyDetails:sector'),
      dataIndex: CompanyCreditBalance.sector,
      key: CompanyCreditBalance.sector,
      sorter: false,
      align: 'left' as const,
    },
    {
      title: t('companyDetails:quantity'),
      dataIndex: CompanyCreditBalance.quantity,
      key: CompanyCreditBalance.quantity,
      align: 'left' as const,
      sorter: (a: any, b: any) =>
        a[CompanyCreditBalance.quantity] - b[CompanyCreditBalance.quantity],
      render: (quantity: number) => {
        return addCommSep(quantity);
      },
    },
    {
      title: t('companyDetails:creditOwnership'),
      dataIndex: CompanyCreditBalance.isProgrammeOwner,
      key: CompanyCreditBalance.isProgrammeOwner,
      align: 'left' as const,
      render: (item: boolean) => {
        return item ? t('common:yes') : t('common:no');
      },
    },
  ];

  const onClickTransferToExchange = () => {
    setIsShowCreditTransferModel(true);
  };

  return (
    <>
      {contextHolder}
      {isShowCreditTransferModel && (
        <Modal
          title={
            <div className="popup-header">
              <div className="icon">
                {' '}
                <Icon.BoxArrowRight />
              </div>
              <div>{t('companyDetails:sendCredit')}</div>
            </div>
          }
          footer
          open={true}
          className="action-dialog popup-primary"
          onCancel={() => setIsShowCreditTransferModel(false)}
        >
          <CreditTransferForm
            isKiudaExTransfer={true}
            companyRole={userInfoState!.companyRole}
            receiverLabelText={t('view:to')}
            userCompanyId={userInfoState?.companyId}
            programmes={creditTableData}
            subText={t('view:popupText')}
            onCancel={() => {
              setIsShowCreditTransferModel(false);
            }}
            actionBtnText={t('view:sendToEx')}
            onTransferSuccess={(body: any) => {
              setIsShowCreditTransferModel(false);
              setIsLoading(true);
              getOrganizationData();
              messageApi.open({
                type: 'success',
                content: t('common:transferSuccessful'),
                duration: 3,
                style: { textAlign: 'right', marginRight: 15, marginTop: 10 },
              });
            }}
            translator={t}
            ministryLevelPermission={false}
          />
        </Modal>
      )}
      {organizationDetails &&
      (userInfoState!.companyRole === CompanyRole.GOVERNMENT ||
        userInfoState!.companyId === organizationDetails?.companyId) ? (
        <Card className="card-container">
          <div className="info-view">
            <div className="section-title">
              <div>
                <span className="title-icon">
                  <SketchOutlined />
                </span>
                <span className="title-text">{t('companyDetails:creditBalance')}</span>
                <span style={{ marginTop: '2px', marginLeft: '2px' }}>
                  <Tooltip
                    arrowPointAtCenter
                    placement="top"
                    trigger="hover"
                    style={{ marginLeft: '15px', marginTop: '2px' }}
                    title={t('companyDetails:creditBalanceTooltip')}
                  >
                    <InfoCircle color="#000000" size={17} />
                  </Tooltip>
                </span>
              </div>
              <Button
                type="primary"
                size="middle"
                onClick={onClickTransferToExchange}
                disabled={
                  creditTableData.length === 0 ||
                  userInfoState!.companyRole === CompanyRole.GOVERNMENT
                }
              >
                {t('view:sendToEx')}
              </Button>
            </div>
            <Skeleton loading={false} active>
              <Row className="field" style={{ margin: '10px 0' }}>
                <Col span={24}>
                  <Table
                    dataSource={creditTableData}
                    columns={columns}
                    className="mt-2.5 w-screen"
                    size="middle"
                    loading={isLoading}
                    pagination={{
                      pageSize: pageSize,
                      total: creditTableData.length,
                      showQuickJumper: true,
                      showSizeChanger: true,
                      onChange: (page, tPageSize) => {
                        setPageSize(tPageSize);
                      },
                    }}
                    locale={{
                      emptyText: (
                        <Empty
                          className="bg-gray-bg-color py-5"
                          description={
                            creditTableData.length === 0 ? t('companyDetails:noRecords') : null
                          }
                        />
                      ),
                    }}
                  ></Table>
                </Col>
              </Row>
            </Skeleton>
          </div>
        </Card>
      ) : null}
    </>
  );
};
